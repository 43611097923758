.nejctenejsi_clanky {
   padding: 2rem 1rem;

   h2 {
      color: $font_color;
      font-weight: 700;
      font-size: 2.6rem;
      margin-bottom: 3rem;
   }

   .row {
      display: grid;
      grid-column-gap: 2rem;
      grid-row-gap: 1rem;

      &.two_box {
         grid-template-columns: 100%;
         grid-row-gap: 1rem;
         margin-bottom: 1rem;

         .box {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 2rem;
            margin-bottom: 2rem;

            .article_block {
               display: flex;
               flex-direction: column;
               justify-content: space-between;
            }

            @include info_block;

            @include content_block;
         }
      }

      &.three_box {
         grid-template-columns: 100%;

         .box {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 1rem;

            .img_box {
               width: 100%;
               height: auto;
            }

            .article_block {
               display: flex;
               flex-direction: column;
               justify-content: space-between;
            }

            @include info_block;

            @include content_block;

            .content {
               margin: 0 0 1rem 0;

               .text {
                  font-size: 1.7rem;
               }
            }
         }
      }
   }

   .btn_box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 12rem;
      border-bottom: 1px solid $border_color;
   }
}

@media (min-width: $break_tablet) {
   .nejctenejsi_clanky {
      padding: 4rem 1rem;

      .row {
         display: grid;

         &.two_box {
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 4rem;

            .box {
               grid-template-columns: 100%;
               grid-row-gap: 2rem;
               margin-bottom: 0;
            }
         }

         &.three_box {
            grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
            grid-gap: 2rem;

            .box {
               grid-template-columns: 10rem 1fr;
            }
         }
      }

      .btn_box {
         display: flex;
         align-items: center;
         justify-content: center;
         height: 12rem;
         border-bottom: 1px solid $border_color;
      }
   }
}

@media (min-width: $break_desktop) {
   .nejctenejsi_clanky {
      padding: 4rem 1rem 2rem 1rem;

      .row {
         display: grid;

         &.two_box {
            grid-template-columns: auto auto;
            margin-bottom: 4rem;

            .box {
               grid-template-columns: repeat(2, 1fr);

               .img_box {
                  width: 100%;
                  height: 20.6rem;
               }
            }
         }

         &.three_box {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 3rem;

            .box {
               grid-template-columns: 13rem 1fr;
               grid-gap: 2rem;
            }
         }
      }
   }
}