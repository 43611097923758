.btn {
   display: inline-block;
   font-size: 1.4rem;
   text-transform: uppercase;

   &:hover {
      cursor: pointer;
   }

   &_box {
      text-align: center;
   }

   &_blue {
      background-color: $base_color_2;
      color: $base_color_1;
      padding: 1rem 1.7rem;

      &:hover {
         background-color: darken($base_color_2, 10);
         transition: all .2s;
      }
   }
}