.nenechte_si_ujit {
   padding: 2rem 1rem;

   h2 {
      color: $font_color;
      font-weight: 700;
      font-size: 2.6rem;
      margin: 3rem 0;
   }

   .row {
      display: grid;
      grid-template-columns: 100%;

      .box {
         display: grid;
         grid-template-columns: 100%;
         grid-gap: 2rem;

         &:not(:last-child) {
            margin-bottom: 4rem;
         }

         .article_block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
               margin-bottom: 1rem;
            }

            @include info_block;

            @include content_block;

            .content {
               margin: 0;
            }
         }

         .article {
            position: relative;
            height: inherit;

            &:hover {
               cursor: pointer;

               .info {
                  margin: auto;
                  min-height: 75%;
                  transition: all .2s;
               }
            }

            .info {
               min-height: 30%;
               width: 100%;
               position: absolute;
               bottom: 0;
               left: 0;
               background-color: rgba($base_color_4, .75);
               color: $base_color_1;
               font-size: 2rem;
               padding: 2rem;

               .date {
                  font-size: 1.4rem;
                  color: $font_color_light;
                  font-weight: 700;
               }
            }
         }
      }
   }
}

@media (min-width: $break_tablet) {
   .nenechte_si_ujit {
      padding: 2rem 1rem 4rem 1rem;
      .row {
         .box {
            grid-template-columns: repeat(2, 1fr);
            .img_box {
               width: 100%;
               height: 20.6rem;
            }
         }
      }
   }
}

@media (min-width: $break_desktop) {
   .nenechte_si_ujit {
      .row {
         grid-template-columns: repeat(2, 1fr);
         grid-column-gap: 2rem;

         .box {
            grid-template-columns: repeat(2, 1fr);

            .img_box {
               width: 100%;
               height: 20.6rem;
            }
         }
      }
   }
}