.footer_menu {
   background-color: $base_color_2;
   text-align: center;
   padding: 0 1rem;

   .inner {
      display: block;
      align-items: center;
      justify-content: space-between;
      max-width: $body_width;
      width: 100%;
      margin: 0 auto;
      padding: 1rem 0;

      .left {
         .link {
            display: block;
            color: $base_color_1;
            font-size: 1.6rem;
            font-weight: 700;
            text-transform: uppercase;
            margin: 1rem 0;

            &:hover {
               color: darken($base_color_1, 10);
            }
         }
      }

      .social {
         display: inline-flex;
         margin-top: 2rem;

         .icon {
            width: 3rem;
            height: 2rem;

            &:not(:last-child) {
               margin-right: 2rem;
            }

            &:hover {
               transition: all .2s;
            }

            &.fb {
               background: url("../img/icon/fb.png") center center no-repeat;

               &:hover {
                  background: url("../img/icon/fb-hover.png") center center no-repeat;
               }
            }

            &.twitter {
               background: url("../img/icon/twitter.png") center center no-repeat;

               &:hover {
                  background: url("../img/icon/twitter-hover.png") center center no-repeat;
               }
            }

            &.ig {
               background: url("../img/icon/ig.png") center center no-repeat;

               &:hover {
                  background: url("../img/icon/ig-hover.png") center center no-repeat;
               }
            }

            &.yt {
               background: url("../img/icon/yt.png") center center no-repeat;

               &:hover {
                  background: url("../img/icon/yt-hover.png") center center no-repeat;
               }
            }
         }
      }
   }
}

.footer {
   color: $font_color;
   background-color: $base_color_5;
   padding: 0 1rem;

   .head {
      font-size: 1.8rem;
      font-weight: 700;
      color: $base_color_1;
      margin-bottom: 2.5rem;
   }

   .link {
      color: $font_color;
      line-height: 1.7;

      &:hover {
         color: $font_color_light;
      }

      &.contact_us {
         color: $base_color_2;
      }
   }

   .footer_nav {
      .inner {
         max-width: $body_width;
         width: 100%;
         margin: 0 auto;
         display: grid;
         grid-template-columns: 100%;
         padding: 2rem 0 0 0;

         .box {
            line-height: 1.5;
            font-size: 1.6rem;
            margin-bottom: 1rem;

            &.active {
               .chevron {
                  transform: rotateZ(180deg);
                  transition: all .2s;
               }
            }

            .head {
               margin-bottom: 0;
               width: 100%;
               display: inline-flex;
               justify-content: space-between;
               align-items: center;
            }

            .chevron {
               width: 1rem;
               height: .7rem;
               background: url("../img/icon/chevron_down.svg") center center no-repeat;
               transition: all .2s;
            }

            .collapse_body {
               margin: 2rem 0;
            }

            address {
               font-style: normal;
               margin-bottom: 2rem;
            }

            .tel {
               display: block;
               color: $base_color_1;
               font-weight: 700;
               margin-bottom: .5rem;
            }

            .mail {
               display: block;
               color: $base_color_2;
               font-weight: 700;
            }

            form {
               width: 100%;
               display: inline-flex;
               margin-top: 2rem;

               input[type="submit"] {
                  background: url("../img/icon/email.png") center bottom no-repeat;
                  border: 0;
                  width: 4rem;
                  height: 3.9rem;
                  color: transparent;
                  cursor: pointer;
               }
            }
         }
      }
   }

   .advertising {
      text-align: center;

      .inner {
         max-width: $body_width;
         width: 100%;
         margin: 0 auto;
         display: block;
         padding: 2rem 0;
         border-top: 1px solid darken($border_color, 15);
         border-bottom: 1px solid darken($border_color, 15);
         text-align: center;

         .box {
            display: block;
            font-size: 1.6rem;

            &:not(:last-child) {
               margin-bottom: 2rem;
            }

            .head {
               margin: 0;
            }

            .link {
               margin-left: .5rem;
            }
         }
      }
   }

   .partners {
      .inner {
         max-width: $body_width;
         width: 100%;
         margin: 0 auto;
         display: block;
         padding: 2rem 0;

         .box {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
            align-items: center;
            justify-content: center;
            font-size: 1.6rem;

            .head {
               margin: 0;
            }

            .link {
               margin-left: .5rem;
            }
         }
      }
   }

   .created {
      text-align: center;
      background: darken($base_color_5, 7);
      margin: 0 -1rem;
      padding: 0 1rem;

      .inner {
         max-width: $body_width;
         width: 100%;
         margin: 0 auto;
         display: block;
         padding: 2rem 0;

         .box {
            font-size: 1.4rem;

            .link {
               color: $font_color;

               &:hover {
                  color: $font_color_light;
               }
            }
         }
      }
   }
}

@media (min-width: $break_tablet) {
   .footer_menu {
      .inner {
         display: inline-flex;
         height: 8rem;

         .left {
            line-height: 2;
            text-align: left;

            .link {
               display: inline-flex;
               margin: 0;

               &:not(:last-child) {
                  margin-right: 2rem;
               }

               &:hover {
                  color: darken($base_color_1, 10);
               }
            }
         }

         .social {
            margin-top: 0;
         }
      }
   }

   .footer {
      .footer_nav {
         .inner {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 2rem;

            .box {
               padding-right: 4rem;

               .chevron {
                  display: none;
               }

               .collapse_body {
                  display: block;
               }

               &:not(:last-child) {
                  border-right: 2px dotted $font_color;
               }

               &:nth-child(2) {
                  border: none;
               }
            }
         }
      }

      .partners {
         .inner {
            .box {
               grid-template-columns: repeat(4, 1fr);
            }
         }
      }

      .created {
         .inner {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
         }
      }
   }
}

@media (min-width: $break_desktop) {
   .footer_menu {
      .inner {
         .left {
            line-height: unset;

            .link {
               font-size: 1.8rem;
            }
         }
      }
   }

   .footer {
      .footer_nav {
         .inner {
            grid-template-columns: repeat(4, 1fr);
            padding: 5rem 0 4rem 0;

            .box {
               margin-bottom: 0;

               &:nth-child(2) {
                  border-right: 2px dotted $font_color;
               }
            }
         }
      }

      .advertising {
         .inner {
            display: inline-flex;
            align-items: center;
            justify-content: space-around;

            .box {
               display: inline-flex;
               align-items: center;
               justify-content: space-around;

               &:not(:last-child) {
                  margin-bottom: 0;
               }

               .head {
                  &:after {
                     display: inline-flex;
                     width: 2px;
                     height: 13px;
                     content: "";
                     background-color: $base_color_1;
                     margin: 0 1rem;
                  }
               }
            }
         }
      }

      .partners {
         .inner {
            .box {
               display: inline-flex;
               align-items: center;
               justify-content: space-around;
            }
         }
      }
   }
}