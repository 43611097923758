/* PROJECT UNITS */
/* PROJECT COLORS */
* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
  outline: none; }

html {
  overflow-x: hidden;
  font-size: 62.5%; }

body {
  overflow-x: hidden;
  position: relative;
  background-color: #ffffff; }

hr {
  margin: 2rem 0;
  border-top: 1px solid #c8c8c8; }

.collapse_body,
.collapse_body_user {
  display: none; }

.bold {
  color: #333333;
  font-weight: 700; }

a {
  text-decoration: none; }
  a:hover {
    cursor: pointer; }

ul {
  list-style: none; }

.img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.tag {
  vertical-align: text-bottom;
  margin: 0 1rem .6rem 0;
  display: inline-block;
  padding: .5rem 1.3rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 700; }
  .tag.analyza {
    background-color: #ffc240;
    color: #000000; }
  .tag.komentar {
    background-color: #fe2a60;
    color: #ffffff; }
  .tag.clanek {
    background-color: #05abce;
    color: #ffffff; }
  .tag.priroda {
    background-color: #45a281;
    color: #ffffff; }
  .tag.rozhovor {
    background-color: #6d6295;
    color: #ffffff; }

.btn {
  display: inline-block;
  font-size: 1.4rem;
  text-transform: uppercase; }
  .btn:hover {
    cursor: pointer; }
  .btn_box {
    text-align: center; }
  .btn_blue {
    background-color: #3c73b9;
    color: #ffffff;
    padding: 1rem 1.7rem; }
    .btn_blue:hover {
      background-color: #305b92;
      transition: all .2s; }

.gallery .gallery_row {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 2rem; }
  .gallery .gallery_row:not(:last-child) {
    margin-bottom: 2rem; }
  .gallery .gallery_row .big img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .gallery .gallery_row .four_box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem; }
    .gallery .gallery_row .four_box img {
      max-width: 100%;
      height: auto; }

.gallery .btn_box {
  text-align: center;
  padding: 2rem 0 5rem;
  margin-bottom: 5rem;
  border-bottom: 1px solid #bbbbbb; }

@media (min-width: 600px) {
  .gallery .gallery_row {
    grid-template-columns: repeat(2, 1fr); } }

@media (min-width: 1200px) {
  .gallery .gallery_row .big {
    max-width: 28.5rem; } }

.comment {
  border-left: 5px solid #e2e2e2;
  padding-left: 2rem; }
  .comment:not(:last-child) {
    margin-bottom: 2rem; }
  .comment .name {
    display: inline-flex;
    align-items: center; }
    .comment .name .person_icon {
      width: 3rem;
      height: 3rem;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 1rem; }
    .comment .name span {
      font-size: 1.6rem;
      color: #888888;
      font-weight: 700; }
  .comment .head {
    font-size: 2rem;
    color: #333333;
    font-weight: 700;
    margin: .5rem 0; }
  .comment .text {
    font-size: 1.6rem;
    font-style: italic;
    color: #888888; }

.field {
  width: 100%;
  display: flex;
  flex-flow: column-reverse; }

label, input {
  transition: all 0.2s;
  touch-action: manipulation; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent; }

input::placeholder {
  color: transparent; }

input {
  width: 100%;
  font-size: 1.6rem;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #888888;
  -webkit-appearance: none;
  border-radius: 0;
  cursor: text;
  padding: 0;
  color: #888888; }

input:focus {
  outline: 0;
  border-bottom: 1px solid #3c73b9; }

input:not(:valid) {
  border-bottom: 1px solid #fe2a60; }

label {
  letter-spacing: 0.05rem;
  color: #888888;
  margin-bottom: .5rem;
  font-size: 1.4rem; }

input:placeholder-shown + label {
  cursor: text;
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  transform: translate(0, 2rem) scale(1.5);
  color: #888888; }

::-webkit-input-placeholder {
  opacity: 0; }

input:not(:placeholder-shown) + label,
input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.header {
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-areas: "logo mobile" "search search" "menu menu";
  position: relative; }
  .header .logo_wrapper {
    position: fixed;
    width: 100%;
    z-index: 100;
    grid-area: logo;
    padding: 1rem 1rem 0 1rem;
    background-color: #ffffff;
    box-shadow: 0 0.5px 5px #888888; }
    .header .logo_wrapper img {
      max-height: 4rem; }
  .header .list {
    display: none; }
  .header .user_block .user {
    display: none;
    visibility: hidden; }
  .header .user_block .fb_login {
    display: block;
    visibility: visible; }
  .header .user_block.logged .user {
    display: block;
    visibility: visible; }
  .header .user_block.logged .fb_login {
    display: none;
    visibility: hidden; }
  .header .user {
    font-size: 1.4rem;
    position: relative;
    top: unset;
    left: unset; }
    .header .user .link {
      display: inline-flex;
      align-items: center; }
      .header .user .link .img_wrapper {
        width: 3rem;
        height: 3rem;
        border-radius: 50%; }
        .header .user .link .img_wrapper img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%; }
      .header .user .link .username {
        display: none; }
      .header .user .link .chevron {
        display: none; }
    .header .user.active .chevron {
      transform: rotateZ(180deg);
      transition: all .2s; }
    .header .user .user_menu {
      background-color: #ffffff;
      z-index: 110;
      position: absolute;
      right: 0; }
      .header .user .user_menu ul {
        list-style: none;
        display: block;
        margin: 1rem 0 0 auto;
        width: 12rem;
        line-height: 1.6;
        border: 1px solid #3c73b9;
        padding: 1.5rem; }
        .header .user .user_menu ul li:after {
          display: none !important; }
        .header .user .user_menu ul li .link {
          color: #888888; }
          .header .user .user_menu ul li .link:hover {
            color: #333333; }
  .header .fb_login {
    max-width: 20rem;
    width: 100%;
    text-align: left;
    font-size: 1.4rem;
    color: #3c73b9; }
    .header .fb_login:hover {
      color: #305b92;
      cursor: pointer; }
    .header .fb_login span {
      display: none; }
  .header .search {
    width: 100%;
    margin-top: 5.2rem;
    grid-area: search;
    box-shadow: 1px 1px 1px #bbbbbb;
    padding: 1rem; }
    .header .search form {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start; }
      .header .search form input[type="submit"] {
        background: url("../img/icon/lupa.png") center center no-repeat;
        color: transparent;
        border: none;
        width: 3rem;
        height: 3rem;
        margin-left: 2rem;
        cursor: pointer; }
  .header .mobile {
    display: grid;
    grid-template-columns: fit-content(13rem) fit-content(4rem);
    grid-column-gap: 1.5rem;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    right: 0;
    z-index: 100;
    max-width: 13rem;
    min-width: 11rem;
    width: 100%;
    grid-area: mobile;
    padding: 1rem; }
    .header .mobile .mobile_toggler {
      display: block;
      width: 3rem;
      padding: 1.5rem 0; }
      .header .mobile .mobile_toggler .hamburger {
        display: block;
        width: 3rem;
        height: 2px;
        background-color: #333333;
        position: relative;
        transition: all .2s; }
        .header .mobile .mobile_toggler .hamburger:before {
          position: absolute;
          top: -1rem;
          display: block;
          content: "";
          width: 3rem;
          height: 2px;
          background-color: #333333;
          transition: all .2s; }
        .header .mobile .mobile_toggler .hamburger:after {
          position: absolute;
          top: 1rem;
          display: block;
          content: "";
          width: 3rem;
          height: 2px;
          background-color: #333333; }
  .header .nav {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 5.2rem;
    z-index: 100;
    grid-area: menu;
    background-color: #3c73b9;
    color: #ffffff;
    text-align: center;
    padding: 2rem 0 10rem 0; }
    .header .nav .menu {
      grid-area: menu; }
      .header .nav .menu .head {
        font-size: 2.4rem;
        font-weight: 700;
        margin-bottom: 2rem; }
      .header .nav .menu nav ul {
        display: block;
        align-items: center; }
        .header .nav .menu nav ul li {
          margin-bottom: 1rem; }
          .header .nav .menu nav ul li .link {
            font-size: 1.8rem;
            font-weight: 700; }
            .header .nav .menu nav ul li .link:hover {
              color: #e6e6e6; }
    .header .nav .list {
      display: block;
      position: absolute;
      z-index: 100;
      grid-area: list;
      width: 100%;
      background-color: #3c73b9;
      padding: 2rem 1rem 0 1rem; }
      .header .nav .list ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        font-size: 1.6rem;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        padding: 1rem 0; }
        .header .nav .list ul li {
          text-align: center; }
          .header .nav .list ul li .link {
            color: #ffffff; }
            .header .nav .list ul li .link:hover {
              color: #333333; }
      .header .nav .list .fb_login {
        display: block;
        text-align: center;
        padding: 2rem 0; }
        .header .nav .list .fb_login .link {
          font-size: 1.6rem;
          color: #ffffff; }
          .header .nav .list .fb_login .link:hover {
            text-decoration: underline; }
  .header.active .mobile .hamburger {
    transform: rotateZ(45deg);
    top: 0;
    right: 0;
    transition: all .2s; }
    .header.active .mobile .hamburger:before {
      transform: rotateZ(-90deg);
      top: 0;
      right: 0;
      transition: all .2s; }
    .header.active .mobile .hamburger:after {
      display: none; }

@media (min-width: 1200px) {
  .header {
    max-width: 100%;
    grid-template-areas: "logo list" "logo search" "menu menu";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto 10rem;
    height: 24rem;
    padding: 0 1rem;
    position: relative; }
    .header .user .link .username {
      display: block;
      margin-left: 2rem;
      color: #3c73b9; }
    .header .user .link .chevron {
      display: inline-flex;
      margin-left: 1.5rem;
      transition: all .2s; }
    .header .logo_wrapper {
      max-width: 60rem;
      padding-left: calc(100% - 60rem);
      text-align: right;
      position: unset;
      grid-area: logo;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-bottom: 2rem;
      background-color: transparent;
      box-shadow: none; }
      .header .logo_wrapper img {
        max-height: 7rem; }
    .header .list {
      min-height: 4rem;
      max-width: 60rem;
      width: 100%;
      display: grid;
      grid-template-columns: fit-content(40rem) fit-content(20rem);
      align-items: center;
      justify-content: space-between; }
      .header .list ul {
        width: 100%;
        display: inline-flex; }
        .header .list ul li:not(:last-child):after {
          display: inline-flex;
          content: "";
          width: 1rem;
          height: 1rem;
          background: url("../img/icon/bullet.png") center center no-repeat;
          margin: 0 1rem; }
        .header .list ul li .link {
          font-size: 1.4rem;
          color: #888888; }
          .header .list ul li .link:hover {
            color: #6f6f6f; }
    .header .search {
      max-width: 60rem;
      width: 100%;
      margin-top: 0;
      grid-area: search;
      box-shadow: none;
      padding: 0; }
      .header .search form {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start; }
        .header .search form input[type="submit"] {
          background: url("../img/icon/lupa.png") center center no-repeat;
          color: transparent;
          border: none;
          width: 3rem;
          height: 3rem;
          margin-left: 2rem;
          cursor: pointer; }
    .header .nav {
      display: block;
      grid-area: menu;
      height: 10rem;
      width: calc(100% + 2rem);
      margin-left: -1rem;
      position: relative;
      left: 0;
      top: 0;
      background-color: #3c73b9;
      text-align: center;
      padding: 0 1rem; }
      .header .nav .menu {
        max-width: 120rem;
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 10rem; }
        .header .nav .menu .head {
          color: #ffffff;
          font-size: 2.4rem;
          font-weight: 700;
          margin-bottom: 0;
          padding-right: 3rem;
          margin-right: 3rem;
          border-right-style: dotted;
          border-right-color: #ffffff; }
        .header .nav .menu nav ul {
          display: inline-flex;
          align-items: center; }
          .header .nav .menu nav ul li {
            margin-bottom: 0; }
            .header .nav .menu nav ul li:not(:last-child) {
              margin-right: 3rem; }
            .header .nav .menu nav ul li .link {
              font-size: 1.8rem;
              font-weight: 700;
              color: #ffffff; }
              .header .nav .menu nav ul li .link:hover {
                color: #e6e6e6; }
    .header .fb_login span {
      display: inline-flex; }
    .header .mobile {
      display: none; } }

.section_wrapper {
  max-width: 120rem;
  width: 100%;
  margin: 0 auto; }

.footer_menu {
  background-color: #3c73b9;
  text-align: center;
  padding: 0 1rem; }
  .footer_menu .inner {
    display: block;
    align-items: center;
    justify-content: space-between;
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0; }
    .footer_menu .inner .left .link {
      display: block;
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 700;
      text-transform: uppercase;
      margin: 1rem 0; }
      .footer_menu .inner .left .link:hover {
        color: #e6e6e6; }
    .footer_menu .inner .social {
      display: inline-flex;
      margin-top: 2rem; }
      .footer_menu .inner .social .icon {
        width: 3rem;
        height: 2rem; }
        .footer_menu .inner .social .icon:not(:last-child) {
          margin-right: 2rem; }
        .footer_menu .inner .social .icon:hover {
          transition: all .2s; }
        .footer_menu .inner .social .icon.fb {
          background: url("../img/icon/fb.png") center center no-repeat; }
          .footer_menu .inner .social .icon.fb:hover {
            background: url("../img/icon/fb-hover.png") center center no-repeat; }
        .footer_menu .inner .social .icon.twitter {
          background: url("../img/icon/twitter.png") center center no-repeat; }
          .footer_menu .inner .social .icon.twitter:hover {
            background: url("../img/icon/twitter-hover.png") center center no-repeat; }
        .footer_menu .inner .social .icon.ig {
          background: url("../img/icon/ig.png") center center no-repeat; }
          .footer_menu .inner .social .icon.ig:hover {
            background: url("../img/icon/ig-hover.png") center center no-repeat; }
        .footer_menu .inner .social .icon.yt {
          background: url("../img/icon/yt.png") center center no-repeat; }
          .footer_menu .inner .social .icon.yt:hover {
            background: url("../img/icon/yt-hover.png") center center no-repeat; }

.footer {
  color: #888888;
  background-color: #333333;
  padding: 0 1rem; }
  .footer .head {
    font-size: 1.8rem;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 2.5rem; }
  .footer .link {
    color: #888888;
    line-height: 1.7; }
    .footer .link:hover {
      color: #c8c8c8; }
    .footer .link.contact_us {
      color: #3c73b9; }
  .footer .footer_nav .inner {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 100%;
    padding: 2rem 0 0 0; }
    .footer .footer_nav .inner .box {
      line-height: 1.5;
      font-size: 1.6rem;
      margin-bottom: 1rem; }
      .footer .footer_nav .inner .box.active .chevron {
        transform: rotateZ(180deg);
        transition: all .2s; }
      .footer .footer_nav .inner .box .head {
        margin-bottom: 0;
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center; }
      .footer .footer_nav .inner .box .chevron {
        width: 1rem;
        height: .7rem;
        background: url("../img/icon/chevron_down.svg") center center no-repeat;
        transition: all .2s; }
      .footer .footer_nav .inner .box .collapse_body {
        margin: 2rem 0; }
      .footer .footer_nav .inner .box address {
        font-style: normal;
        margin-bottom: 2rem; }
      .footer .footer_nav .inner .box .tel {
        display: block;
        color: #ffffff;
        font-weight: 700;
        margin-bottom: .5rem; }
      .footer .footer_nav .inner .box .mail {
        display: block;
        color: #3c73b9;
        font-weight: 700; }
      .footer .footer_nav .inner .box form {
        width: 100%;
        display: inline-flex;
        margin-top: 2rem; }
        .footer .footer_nav .inner .box form input[type="submit"] {
          background: url("../img/icon/email.png") center bottom no-repeat;
          border: 0;
          width: 4rem;
          height: 3.9rem;
          color: transparent;
          cursor: pointer; }
  .footer .advertising {
    text-align: center; }
    .footer .advertising .inner {
      max-width: 120rem;
      width: 100%;
      margin: 0 auto;
      display: block;
      padding: 2rem 0;
      border-top: 1px solid #959595;
      border-bottom: 1px solid #959595;
      text-align: center; }
      .footer .advertising .inner .box {
        display: block;
        font-size: 1.6rem; }
        .footer .advertising .inner .box:not(:last-child) {
          margin-bottom: 2rem; }
        .footer .advertising .inner .box .head {
          margin: 0; }
        .footer .advertising .inner .box .link {
          margin-left: .5rem; }
  .footer .partners .inner {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    display: block;
    padding: 2rem 0; }
    .footer .partners .inner .box {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem; }
      .footer .partners .inner .box .head {
        margin: 0; }
      .footer .partners .inner .box .link {
        margin-left: .5rem; }
  .footer .created {
    text-align: center;
    background: #212121;
    margin: 0 -1rem;
    padding: 0 1rem; }
    .footer .created .inner {
      max-width: 120rem;
      width: 100%;
      margin: 0 auto;
      display: block;
      padding: 2rem 0; }
      .footer .created .inner .box {
        font-size: 1.4rem; }
        .footer .created .inner .box .link {
          color: #888888; }
          .footer .created .inner .box .link:hover {
            color: #c8c8c8; }

@media (min-width: 600px) {
  .footer_menu .inner {
    display: inline-flex;
    height: 8rem; }
    .footer_menu .inner .left {
      line-height: 2;
      text-align: left; }
      .footer_menu .inner .left .link {
        display: inline-flex;
        margin: 0; }
        .footer_menu .inner .left .link:not(:last-child) {
          margin-right: 2rem; }
        .footer_menu .inner .left .link:hover {
          color: #e6e6e6; }
    .footer_menu .inner .social {
      margin-top: 0; }
  .footer .footer_nav .inner {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem; }
    .footer .footer_nav .inner .box {
      padding-right: 4rem; }
      .footer .footer_nav .inner .box .chevron {
        display: none; }
      .footer .footer_nav .inner .box .collapse_body {
        display: block; }
      .footer .footer_nav .inner .box:not(:last-child) {
        border-right: 2px dotted #888888; }
      .footer .footer_nav .inner .box:nth-child(2) {
        border: none; }
  .footer .partners .inner .box {
    grid-template-columns: repeat(4, 1fr); }
  .footer .created .inner {
    display: inline-flex;
    justify-content: space-between;
    align-items: center; } }

@media (min-width: 1200px) {
  .footer_menu .inner .left {
    line-height: unset; }
    .footer_menu .inner .left .link {
      font-size: 1.8rem; }
  .footer .footer_nav .inner {
    grid-template-columns: repeat(4, 1fr);
    padding: 5rem 0 4rem 0; }
    .footer .footer_nav .inner .box {
      margin-bottom: 0; }
      .footer .footer_nav .inner .box:nth-child(2) {
        border-right: 2px dotted #888888; }
  .footer .advertising .inner {
    display: inline-flex;
    align-items: center;
    justify-content: space-around; }
    .footer .advertising .inner .box {
      display: inline-flex;
      align-items: center;
      justify-content: space-around; }
      .footer .advertising .inner .box:not(:last-child) {
        margin-bottom: 0; }
      .footer .advertising .inner .box .head:after {
        display: inline-flex;
        width: 2px;
        height: 13px;
        content: "";
        background-color: #ffffff;
        margin: 0 1rem; }
  .footer .partners .inner .box {
    display: inline-flex;
    align-items: center;
    justify-content: space-around; } }

.peclive_vybrano {
  background-color: #ffffff;
  padding: 2rem 1rem; }
  .peclive_vybrano h2 {
    color: #888888;
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 1.5rem; }
  .peclive_vybrano .row {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 1rem; }
    .peclive_vybrano .row .article {
      position: relative;
      height: inherit; }
      .peclive_vybrano .row .article:hover {
        cursor: pointer; }
        .peclive_vybrano .row .article:hover .info {
          margin: auto;
          min-height: 50%;
          transition: all .2s; }
      .peclive_vybrano .row .article .info {
        min-height: 30%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(14, 36, 44, 0.75);
        color: #ffffff;
        font-size: 2rem;
        padding: 2rem; }
        .peclive_vybrano .row .article .info .date {
          font-size: 1.4rem;
          color: #c8c8c8;
          font-weight: 700; }
    .peclive_vybrano .row .four_box {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 1rem; }
      .peclive_vybrano .row .four_box .article:hover {
        cursor: pointer; }
        .peclive_vybrano .row .four_box .article:hover .info {
          margin: auto;
          min-height: 75%;
          transition: all .2s; }
      .peclive_vybrano .row .four_box .article .info {
        font-size: 2rem;
        padding: 2rem; }

@media (min-width: 600px) {
  .peclive_vybrano .row {
    grid-template-columns: 100%; }
    .peclive_vybrano .row .article .info {
      font-size: 3rem; }
    .peclive_vybrano .row .four_box {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr); } }

@media (min-width: 1200px) {
  .peclive_vybrano {
    padding: 4.5rem 1rem 2.8rem 1rem; }
    .peclive_vybrano .row {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 43rem;
      grid-column-gap: 2rem; }
      .peclive_vybrano .row .four_box {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 2rem;
        height: 20.5rem; } }

.tri_otakzy {
  padding: 2rem 1rem; }
  .tri_otakzy h2 {
    color: #888888;
    font-weight: 700;
    font-size: 2.6rem;
    margin-bottom: 3rem; }
  .tri_otakzy .person_block {
    display: none; }
    .tri_otakzy .person_block.active {
      display: block; }
    .tri_otakzy .person_block .row {
      display: grid;
      grid-template-columns: 100%;
      grid-column-gap: 2rem; }
      .tri_otakzy .person_block .row .person {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2rem 0; }
        .tri_otakzy .person_block .row .person img {
          width: 22rem;
          height: 22rem;
          border-radius: 50%;
          margin-bottom: 1rem; }
        .tri_otakzy .person_block .row .person .name {
          color: #3c73b9;
          font-size: 2.6rem;
          font-weight: 700; }
        .tri_otakzy .person_block .row .person .position {
          font-size: 1.6rem;
          color: #888888;
          font-weight: 700; }
      .tri_otakzy .person_block .row .answer {
        margin: 2rem 0; }
        .tri_otakzy .person_block .row .answer .title {
          font-size: 3rem;
          color: #333333;
          font-weight: 700;
          margin-bottom: 2rem; }
        .tri_otakzy .person_block .row .answer .text {
          font-size: 1.6rem;
          color: #888888;
          margin-bottom: 2rem; }
        .tri_otakzy .person_block .row .answer .fb {
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: space-between; }
          .tri_otakzy .person_block .row .answer .fb .fb_counter img {
            height: 2rem; }
          .tri_otakzy .person_block .row .answer .fb .action {
            display: inline-flex;
            font-size: 1.4rem;
            color: #3c73b9;
            font-weight: 700; }
            .tri_otakzy .person_block .row .answer .fb .action .like {
              display: flex;
              align-items: center;
              height: 3.2rem;
              background: url("../img/icon/like.png") left center no-repeat;
              padding-left: 4rem;
              margin-right: 1rem; }
            .tri_otakzy .person_block .row .answer .fb .action .unlike {
              display: flex;
              align-items: center;
              height: 3.2rem;
              background: url("../img/icon/unlike.png") left center no-repeat;
              padding-left: 4rem; }
  .tri_otakzy .person_change {
    width: 100%;
    display: block;
    margin: 2.5rem 0;
    border-top: 1px solid #bbbbbb; }
    .tri_otakzy .person_change .name_box {
      display: block; }
      .tri_otakzy .person_change .name_box .head {
        font-size: 2rem;
        color: #333333;
        padding: 2rem 1.5rem; }
      .tri_otakzy .person_change .name_box .name {
        display: block;
        padding: 2rem 1.5rem;
        font-size: 2rem;
        color: #3c73b9;
        background-color: #ffffff; }
        .tri_otakzy .person_change .name_box .name span {
          font-size: 1.6rem; }
          .tri_otakzy .person_change .name_box .name span:hover {
            cursor: pointer;
            text-decoration: underline; }
        .tri_otakzy .person_change .name_box .name.active {
          border: 1px solid #bbbbbb;
          position: relative;
          top: -.1rem; }
          .tri_otakzy .person_change .name_box .name.active span {
            position: relative;
            top: .1rem; }
    .tri_otakzy .person_change .show_more {
      text-align: center;
      margin: 1.5rem 0; }

@media (min-width: 600px) {
  .tri_otakzy .person_block .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr); } }

@media (min-width: 1200px) {
  .tri_otakzy .person_block .row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2rem; }
  .tri_otakzy .person_change {
    display: inline-flex;
    align-items: baseline;
    justify-content: space-between; }
    .tri_otakzy .person_change .name_box {
      display: inline-flex; }
      .tri_otakzy .person_change .name_box .name.active {
        border-top: none; } }

.pozitivne_hodnotite {
  background-color: #ebebeb;
  padding: 2rem 1rem; }
  .pozitivne_hodnotite h2 {
    color: #888888;
    font-weight: 700;
    font-size: 2.6rem;
    margin-bottom: 3rem; }
  .pozitivne_hodnotite .box {
    width: 274px !important;
    height: 42rem;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between; }
    .pozitivne_hodnotite .box .img_box {
      position: relative;
      max-width: 32rem; }
      .pozitivne_hodnotite .box .img_box .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background: rgba(0, 0, 0, 0.5); }
      .pozitivne_hodnotite .box .img_box:hover .overlay {
        opacity: 1; }
      .pozitivne_hodnotite .box .img_box .like {
        display: block;
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 2.5rem;
        height: 2rem; }
        .pozitivne_hodnotite .box .img_box .like span {
          display: block;
          width: 2.5rem;
          height: 2rem;
          background: url("../img/icon/srdce.png") center center no-repeat;
          transition: all .2s; }
        .pozitivne_hodnotite .box .img_box .like:hover span {
          background: url("../img/icon/srdce-hover.png") center center no-repeat;
          transition: all .2s; }
    .pozitivne_hodnotite .box .title {
      margin-bottom: 1rem; }
    .pozitivne_hodnotite .box .info {
      display: inline-flex;
      align-items: center;
      height: 2.6rem; }
      .pozitivne_hodnotite .box .info .tag {
        margin: 0; }
      .pozitivne_hodnotite .box .info .date {
        color: #888888;
        font-size: 1.4rem;
        margin: 0 1rem;
        font-weight: 700; }
      .pozitivne_hodnotite .box .info .msg {
        display: flex;
        align-items: center;
        height: 3rem;
        background: url("../img/icon/zprava.png") left center no-repeat;
        padding-left: 2.5rem;
        font-size: 1.4rem;
        color: #3c73b9;
        font-weight: 700; }
    .pozitivne_hodnotite .box .text {
      font-size: 1.6rem;
      color: #888888; }
      .pozitivne_hodnotite .box .text.bold {
        color: #333333;
        font-size: 2rem; }

@media (min-width: 600px) {
  .pozitivne_hodnotite {
    padding: 4rem 1rem; } }

.nejctenejsi_clanky {
  padding: 2rem 1rem; }
  .nejctenejsi_clanky h2 {
    color: #888888;
    font-weight: 700;
    font-size: 2.6rem;
    margin-bottom: 3rem; }
  .nejctenejsi_clanky .row {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem; }
    .nejctenejsi_clanky .row.two_box {
      grid-template-columns: 100%;
      grid-row-gap: 1rem;
      margin-bottom: 1rem; }
      .nejctenejsi_clanky .row.two_box .box {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 2rem;
        margin-bottom: 2rem; }
        .nejctenejsi_clanky .row.two_box .box .article_block {
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
        .nejctenejsi_clanky .row.two_box .box .info {
          display: inline-flex;
          align-items: center;
          height: 2.6rem; }
          .nejctenejsi_clanky .row.two_box .box .info .tag {
            margin: 0; }
          .nejctenejsi_clanky .row.two_box .box .info .date {
            color: #888888;
            font-size: 1.4rem;
            margin: 0 1rem;
            font-weight: 700; }
          .nejctenejsi_clanky .row.two_box .box .info .msg {
            display: flex;
            align-items: center;
            height: 3rem;
            background: url("../img/icon/zprava.png") left center no-repeat;
            padding-left: 2.5rem;
            font-size: 1.4rem;
            color: #3c73b9;
            font-weight: 700; }
        .nejctenejsi_clanky .row.two_box .box .text {
          font-size: 1.6rem;
          color: #888888; }
          .nejctenejsi_clanky .row.two_box .box .text.bold {
            color: #333333;
            font-size: 2rem; }
    .nejctenejsi_clanky .row.three_box {
      grid-template-columns: 100%; }
      .nejctenejsi_clanky .row.three_box .box {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 1rem; }
        .nejctenejsi_clanky .row.three_box .box .img_box {
          width: 100%;
          height: auto; }
        .nejctenejsi_clanky .row.three_box .box .article_block {
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
        .nejctenejsi_clanky .row.three_box .box .info {
          display: inline-flex;
          align-items: center;
          height: 2.6rem; }
          .nejctenejsi_clanky .row.three_box .box .info .tag {
            margin: 0; }
          .nejctenejsi_clanky .row.three_box .box .info .date {
            color: #888888;
            font-size: 1.4rem;
            margin: 0 1rem;
            font-weight: 700; }
          .nejctenejsi_clanky .row.three_box .box .info .msg {
            display: flex;
            align-items: center;
            height: 3rem;
            background: url("../img/icon/zprava.png") left center no-repeat;
            padding-left: 2.5rem;
            font-size: 1.4rem;
            color: #3c73b9;
            font-weight: 700; }
        .nejctenejsi_clanky .row.three_box .box .text {
          font-size: 1.6rem;
          color: #888888; }
          .nejctenejsi_clanky .row.three_box .box .text.bold {
            color: #333333;
            font-size: 2rem; }
        .nejctenejsi_clanky .row.three_box .box .content {
          margin: 0 0 1rem 0; }
          .nejctenejsi_clanky .row.three_box .box .content .text {
            font-size: 1.7rem; }
  .nejctenejsi_clanky .btn_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12rem;
    border-bottom: 1px solid #bbbbbb; }

@media (min-width: 600px) {
  .nejctenejsi_clanky {
    padding: 4rem 1rem; }
    .nejctenejsi_clanky .row {
      display: grid; }
      .nejctenejsi_clanky .row.two_box {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 4rem; }
        .nejctenejsi_clanky .row.two_box .box {
          grid-template-columns: 100%;
          grid-row-gap: 2rem;
          margin-bottom: 0; }
      .nejctenejsi_clanky .row.three_box {
        grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
        grid-gap: 2rem; }
        .nejctenejsi_clanky .row.three_box .box {
          grid-template-columns: 10rem 1fr; }
    .nejctenejsi_clanky .btn_box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 12rem;
      border-bottom: 1px solid #bbbbbb; } }

@media (min-width: 1200px) {
  .nejctenejsi_clanky {
    padding: 4rem 1rem 2rem 1rem; }
    .nejctenejsi_clanky .row {
      display: grid; }
      .nejctenejsi_clanky .row.two_box {
        grid-template-columns: auto auto;
        margin-bottom: 4rem; }
        .nejctenejsi_clanky .row.two_box .box {
          grid-template-columns: repeat(2, 1fr); }
          .nejctenejsi_clanky .row.two_box .box .img_box {
            width: 100%;
            height: 20.6rem; }
      .nejctenejsi_clanky .row.three_box {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3rem; }
        .nejctenejsi_clanky .row.three_box .box {
          grid-template-columns: 13rem 1fr;
          grid-gap: 2rem; } }

.nenechte_si_ujit {
  padding: 2rem 1rem; }
  .nenechte_si_ujit h2 {
    color: #888888;
    font-weight: 700;
    font-size: 2.6rem;
    margin: 3rem 0; }
  .nenechte_si_ujit .row {
    display: grid;
    grid-template-columns: 100%; }
    .nenechte_si_ujit .row .box {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 2rem; }
      .nenechte_si_ujit .row .box:not(:last-child) {
        margin-bottom: 4rem; }
      .nenechte_si_ujit .row .box .article_block {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        .nenechte_si_ujit .row .box .article_block .title {
          margin-bottom: 1rem; }
        .nenechte_si_ujit .row .box .article_block .info {
          display: inline-flex;
          align-items: center;
          height: 2.6rem; }
          .nenechte_si_ujit .row .box .article_block .info .tag {
            margin: 0; }
          .nenechte_si_ujit .row .box .article_block .info .date {
            color: #888888;
            font-size: 1.4rem;
            margin: 0 1rem;
            font-weight: 700; }
          .nenechte_si_ujit .row .box .article_block .info .msg {
            display: flex;
            align-items: center;
            height: 3rem;
            background: url("../img/icon/zprava.png") left center no-repeat;
            padding-left: 2.5rem;
            font-size: 1.4rem;
            color: #3c73b9;
            font-weight: 700; }
        .nenechte_si_ujit .row .box .article_block .text {
          font-size: 1.6rem;
          color: #888888; }
          .nenechte_si_ujit .row .box .article_block .text.bold {
            color: #333333;
            font-size: 2rem; }
        .nenechte_si_ujit .row .box .article_block .content {
          margin: 0; }
      .nenechte_si_ujit .row .box .article {
        position: relative;
        height: inherit; }
        .nenechte_si_ujit .row .box .article:hover {
          cursor: pointer; }
          .nenechte_si_ujit .row .box .article:hover .info {
            margin: auto;
            min-height: 75%;
            transition: all .2s; }
        .nenechte_si_ujit .row .box .article .info {
          min-height: 30%;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba(14, 36, 44, 0.75);
          color: #ffffff;
          font-size: 2rem;
          padding: 2rem; }
          .nenechte_si_ujit .row .box .article .info .date {
            font-size: 1.4rem;
            color: #c8c8c8;
            font-weight: 700; }

@media (min-width: 600px) {
  .nenechte_si_ujit {
    padding: 2rem 1rem 4rem 1rem; }
    .nenechte_si_ujit .row .box {
      grid-template-columns: repeat(2, 1fr); }
      .nenechte_si_ujit .row .box .img_box {
        width: 100%;
        height: 20.6rem; } }

@media (min-width: 1200px) {
  .nenechte_si_ujit .row {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem; }
    .nenechte_si_ujit .row .box {
      grid-template-columns: repeat(2, 1fr); }
      .nenechte_si_ujit .row .box .img_box {
        width: 100%;
        height: 20.6rem; } }
