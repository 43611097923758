.tri_otakzy {
   padding: 2rem 1rem;

   h2 {
      color: $font_color;
      font-weight: 700;
      font-size: 2.6rem;
      margin-bottom: 3rem;
   }

   .person_block {
      display: none;

      &.active {
         display: block;
      }

      .row {
         display: grid;
         grid-template-columns: 100%;
         grid-column-gap: 2rem;

         .person {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 2rem 0;

            img {
               width: 22rem;
               height: 22rem;
               border-radius: 50%;
               margin-bottom: 1rem;
            }

            .name {
               color: $base_color_2;
               font-size: 2.6rem;
               font-weight: 700;
            }

            .position {
               font-size: 1.6rem;
               color: $font_color;
               font-weight: 700;
            }
         }

         .answer {
            margin: 2rem 0;

            .title {
               font-size: 3rem;
               color: $font_color_dark;
               font-weight: 700;
               margin-bottom: 2rem;
            }

            .text {
               font-size: 1.6rem;
               color: $font_color;
               margin-bottom: 2rem;
            }

            .fb {
               width: 100%;
               display: inline-flex;
               align-items: center;
               justify-content: space-between;

               .fb_counter {
                  img {
                     height: 2rem;
                  }
               }

               .action {
                  display: inline-flex;
                  font-size: 1.4rem;
                  color: $base_color_2;
                  font-weight: 700;

                  .like {
                     display: flex;
                     align-items: center;
                     height: 3.2rem;
                     background: url("../img/icon/like.png") left center no-repeat;
                     padding-left: 4rem;
                     margin-right: 1rem;
                  }

                  .unlike {
                     display: flex;
                     align-items: center;
                     height: 3.2rem;
                     background: url("../img/icon/unlike.png") left center no-repeat;
                     padding-left: 4rem;
                  }
               }
            }
         }
      }
   }

   .person_change {
      width: 100%;
      display: block;
      margin: 2.5rem 0;
      border-top: 1px solid $border_color;

      .name_box {
         display: block;

         .head {
            font-size: 2rem;
            color: $font_color_dark;
            padding: 2rem 1.5rem;
         }

         .name {
            display: block;
            padding: 2rem 1.5rem;
            font-size: 2rem;
            color: $base_color_2;
            background-color: $base_color_1;

            span {
               font-size: 1.6rem;

               &:hover {
                  cursor: pointer;
                  text-decoration: underline;
               }
            }

            &.active {
               border: 1px solid $border_color;
               position: relative;
               top: -.1rem;

               span {
                  position: relative;
                  top: .1rem;
               }
            }
         }
      }

      .show_more {
         text-align: center;
         margin: 1.5rem 0;
      }
   }
}

@media (min-width: $break_tablet) {
   .tri_otakzy {
      .person_block {
         .row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
         }
      }
   }
}

@media (min-width: $break_desktop) {
   .tri_otakzy {
      .person_block {
         .row {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 2rem;
         }
      }

      .person_change {
         display: inline-flex;
         align-items: baseline;
         justify-content: space-between;

         .name_box {
            display: inline-flex;

            .name {

               &.active {
                  border-top: none;
               }
            }
         }
      }
   }
}