.field {
   width: 100%;
   display: flex;
   flex-flow: column-reverse;
}

label, input {
   transition: all 0.2s;
   touch-action: manipulation;
}

::-webkit-input-placeholder {
   /* WebKit browsers */
   color: transparent;
}
:-moz-placeholder {
   /* Mozilla Firefox 4 to 18 */
   color: transparent;
}
::-moz-placeholder {
   /* Mozilla Firefox 19+ */
   color: transparent;
}
:-ms-input-placeholder {
   /* Internet Explorer 10+ */
   color: transparent;
}
input::placeholder {
   color: transparent;
}

input {
   width: 100%;
   font-size: 1.6rem;
   background: transparent;
   border: 0;
   border-bottom: 1px solid $font_color;
   -webkit-appearance: none;
   border-radius: 0;
   cursor: text;
   padding: 0;
   color: $font_color;
}

input:focus {
   outline: 0;
   border-bottom: 1px solid $base_color_2;
}

input:not(:valid) {
   border-bottom: 1px solid $tag_color_1;
}

label {
   letter-spacing: 0.05rem;
   color: $font_color;
   margin-bottom: .5rem;
   font-size: 1.4rem;
}

input:placeholder-shown + label {
   cursor: text;
   max-width: 50%;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   transform-origin: left bottom;
   transform: translate(0, 2rem) scale(1.5);
   color: $font_color;
}

::-webkit-input-placeholder {
   opacity: 0;
}

input:not(:placeholder-shown) + label,
input:focus + label {
   transform: translate(0, 0) scale(1);
   cursor: pointer;
}