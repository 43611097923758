.header {
   max-width: $body_width;
   width: 100%;
   margin: 0 auto;
   display: grid;
   grid-template-areas: "logo mobile" "search search" "menu menu";
   position: relative;

   .logo_wrapper {
      position: fixed;
      width: 100%;
      z-index: 100;
      grid-area: logo;
      padding: 1rem 1rem 0 1rem;
      background-color: $base_color_1;
      box-shadow: 0 .5px 5px $font_color;

      img {
         max-height: 4rem;
      }
   }

   .list {
      display: none;
   }

   .user_block {
      .user {
         display: none;
         visibility: hidden;
      }

      .fb_login {
         display: block;
         visibility: visible;
      }

      &.logged {
         .user {
            display: block;
            visibility: visible;
         }

         .fb_login {
            display: none;
            visibility: hidden;
         }
      }
   }

   .user {
      font-size: 1.4rem;
      position: relative;
      top: unset;
      left: unset;

      .link {
         display: inline-flex;
         align-items: center;

         .img_wrapper {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;

            img {
               width: 100%;
               height: 100%;
               object-fit: cover;
               border-radius: 50%;
            }
         }

         .username {
            display: none;
         }

         .chevron {
            display: none;
         }
      }

      &.active {
         .chevron {
            transform: rotateZ(180deg);
            transition: all .2s;
         }
      }

      .user_menu {
         background-color: $base_color_1;
         z-index: 110;
         position: absolute;
         right: 0;

         ul {
            list-style: none;
            display: block;
            margin: 1rem 0 0 auto;
            width: 12rem;
            line-height: 1.6;
            border: 1px solid $base_color_2;
            padding: 1.5rem;

            li {
               &:after {
                  display: none !important;
               }

               .link {
                  color: $font_color;

                  &:hover {
                     color: $font_color_dark;
                  }
               }
            }
         }
      }
   }

   .fb_login {
      max-width: 20rem;
      width: 100%;
      text-align: left;
      font-size: 1.4rem;
      color: $base_color_2;

      &:hover {
         color: darken($base_color_2, 10);
         cursor: pointer;
      }

      span {
         display: none;
      }
   }

   .search {
      width: 100%;
      margin-top: 5.2rem;
      grid-area: search;
      box-shadow: 1px 1px 1px $border_color;
      padding: 1rem;

      form {
         width: 100%;
         display: inline-flex;
         align-items: center;
         justify-content: flex-start;

         input[type="submit"] {
            background: url("../img/icon/lupa.png") center center no-repeat;
            color: transparent;
            border: none;
            width: 3rem;
            height: 3rem;
            margin-left: 2rem;
            cursor: pointer;
         }
      }
   }

   .mobile {
      display: grid;
      grid-template-columns: fit-content(13rem) fit-content(4rem);
      grid-column-gap: 1.5rem;
      align-items: center;
      justify-content: flex-end;
      position: fixed;
      right: 0;
      z-index: 100;
      max-width: 13rem;
      min-width: 11rem;
      width: 100%;
      grid-area: mobile;
      padding: 1rem;

      .mobile_toggler {
         display: block;
         width: 3rem;
         padding: 1.5rem 0;

         .hamburger {
            display: block;
            width: 3rem;
            height: 2px;
            background-color: $base_color_5;
            position: relative;
            transition: all .2s;

            &:before {
               position: absolute;
               top: -1rem;
               display: block;
               content: "";
               width: 3rem;
               height: 2px;
               background-color: $base_color_5;
               transition: all .2s;
            }

            &:after {
               position: absolute;
               top: 1rem;
               display: block;
               content: "";
               width: 3rem;
               height: 2px;
               background-color: $base_color_5;
            }
         }
      }
   }

   .nav {
      display: none;
      position: fixed;
      left: 0;
      right: 0;
      top: 5.2rem;
      z-index: 100;
      grid-area: menu;
      background-color: $base_color_2;
      color: $base_color_1;
      text-align: center;
      padding: 2rem 0 10rem 0;

      .menu {
         grid-area: menu;

         .head {
            font-size: 2.4rem;
            font-weight: 700;
            margin-bottom: 2rem;
         }

         nav {
            ul {
               display: block;
               align-items: center;

               li {
                  margin-bottom: 1rem;

                  .link {
                     font-size: 1.8rem;
                     font-weight: 700;

                     &:hover {
                        color: darken($base_color_1, 10);
                     }
                  }
               }
            }
         }
      }

      .list {
         display: block;
         position: absolute;
         z-index: 100;
         grid-area: list;
         width: 100%;
         background-color: $base_color_2;
         padding: 2rem 1rem 0 1rem;

         ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
            font-size: 1.6rem;
            border-top: 1px solid $base_color_1;
            border-bottom: 1px solid $base_color_1;
            padding: 1rem 0;

            li {
               text-align: center;

               .link {
                  color: $base_color_1;

                  &:hover {
                     color: $font_color_dark;
                  }
               }
            }
         }

         .fb_login {
            display: block;
            text-align: center;
            padding: 2rem 0;

            .link {
               font-size: 1.6rem;
               color: $base_color_1;

               &:hover {
                  text-decoration: underline;
               }
            }
         }
      }
   }

   &.active {
      .mobile {
         .hamburger {
            transform: rotateZ(45deg);
            top: 0;
            right: 0;
            transition: all .2s;

            &:before {
               transform: rotateZ(-90deg);
               top: 0;
               right: 0;
               transition: all .2s;
            }

            &:after {
               display: none;
            }
         }
      }
   }
}

@media (min-width: $break_tablet) {

}

@media (min-width: $break_desktop) {
   .header {
      max-width: 100%;
      grid-template-areas: "logo list" "logo search" "menu menu";
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto auto 10rem;
      height: 24rem;
      padding: 0 1rem;
      position: relative;

      .user {
         .link {
            .username {
               display: block;
               margin-left: 2rem;
               color: $base_color_2;
            }

            .chevron {
               display: inline-flex;
               margin-left: 1.5rem;
               transition: all .2s;
            }
         }
      }

      .logo_wrapper {
         max-width: 60rem;
         padding-left: calc(100% - 60rem);
         text-align: right;
         position: unset;
         grid-area: logo;
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         justify-content: flex-end;
         margin-bottom: 2rem;
         background-color: transparent;
         box-shadow: none;

         img {
            max-height: 7rem;
         }
      }

      .list {
         min-height: 4rem;
         max-width: 60rem;
         width: 100%;
         display: grid;
         grid-template-columns: fit-content(40rem) fit-content(20rem);
         align-items: center;
         justify-content: space-between;

         ul {
            width: 100%;
            display: inline-flex;

            li {

               &:not(:last-child):after {
                  display: inline-flex;
                  content: "";
                  width: 1rem;
                  height: 1rem;
                  background: url("../img/icon/bullet.png") center center no-repeat;
                  margin: 0 1rem;
               }

               .link {
                  font-size: 1.4rem;
                  color: $font_color;

                  &:hover {
                     color: darken($font_color, 10);
                  }
               }
            }
         }
      }

      .search {
         max-width: 60rem;
         width: 100%;
         margin-top: 0;
         grid-area: search;
         box-shadow: none;
         padding: 0;

         form {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            input[type="submit"] {
               background: url("../img/icon/lupa.png") center center no-repeat;
               color: transparent;
               border: none;
               width: 3rem;
               height: 3rem;
               margin-left: 2rem;
               cursor: pointer;
            }
         }
      }

      .nav {
         display: block;
         grid-area: menu;
         height: 10rem;
         width: calc(100% + 2rem);
         margin-left: -1rem;
         position: relative;
         left: 0;
         top: 0;
         background-color: $base_color_2;
         text-align: center;
         padding: 0 1rem;

         .menu {
            max-width: $body_width;
            display: inline-flex;
            align-items: center;
            width: 100%;
            height: 10rem;

            .head {
               color: $base_color_1;
               font-size: 2.4rem;
               font-weight: 700;
               margin-bottom: 0;
               padding-right: 3rem;
               margin-right: 3rem;
               border-right-style: dotted;
               border-right-color: $base_color_1;
            }

            nav {
               ul {
                  display: inline-flex;
                  align-items: center;

                  li {
                     margin-bottom: 0;

                     &:not(:last-child) {
                        margin-right: 3rem;
                     }

                     .link {
                        font-size: 1.8rem;
                        font-weight: 700;
                        color: $base_color_1;

                        &:hover {
                           color: darken($base_color_1, 10);
                        }
                     }
                  }
               }
            }
         }
      }

      .fb_login {
         span {
            display: inline-flex;
         }
      }

      .mobile {
         display: none;
      }
   }
}
