.comment {
   border-left: 5px solid lighten($font_color_light, 10);
   padding-left: 2rem;

   &:not(:last-child) {
      margin-bottom: 2rem;
   }

   .name {
      display: inline-flex;
      align-items: center;

      .person_icon {
         width: 3rem;
         height: 3rem;
         object-fit: cover;
         border-radius: 50%;
         margin-right: 1rem;
      }

      span {
         font-size: 1.6rem;
         color: $font_color;
         font-weight: 700;
      }
   }

   .head {
      font-size: 2rem;
      color: $font_color-dark;
      font-weight: 700;
      margin: .5rem 0;
   }

   .text {
      font-size: 1.6rem;
      font-style: italic;
      color: $font_color;
   }
}