.pozitivne_hodnotite {
   background-color: $section_bg;
   padding: 2rem 1rem;

   h2 {
      color: $font_color;
      font-weight: 700;
      font-size: 2.6rem;
      margin-bottom: 3rem;
   }

   .box {
      width: 274px !important;
      height: 42rem;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;

      .img_box {
         position: relative;
         max-width: 32rem;

         .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: .5s ease;
            background: rgba($base_color_3, .5);
         }

         &:hover .overlay {
            opacity: 1;
         }

         .like {
            display: block;
            position: absolute;
            top: 2rem;
            right: 2rem;
            width: 2.5rem;
            height: 2rem;

            span {
               display: block;
               width: 2.5rem;
               height: 2rem;
               background: url("../img/icon/srdce.png") center center no-repeat;
               transition: all .2s;
            }

            &:hover span {
               background: url("../img/icon/srdce-hover.png") center center no-repeat;
               transition: all .2s;
            }
         }
      }

      .title {
         margin-bottom: 1rem;
      }

      @include info_block;

      @include content_block;
   }
}

@media (min-width: $break_tablet) {
   .pozitivne_hodnotite {
      padding: 4rem 1rem;
   }
}
