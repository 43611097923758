.gallery {
   .gallery_row {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 2rem;

      &:not(:last-child) {
         margin-bottom: 2rem;
      }

      .big {
         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      .four_box {
         display: grid;
         grid-template-columns: repeat(2, 1fr);
         grid-gap: 2rem;

         img {
            max-width: 100%;
            height: auto;
         }
      }
   }

   .btn_box {
      text-align: center;
      padding: 2rem 0 5rem;
      margin-bottom: 5rem;
      border-bottom: 1px solid $border_color;
   }
}

@media (min-width: $break_tablet) {
   .gallery {
      .gallery_row {
         grid-template-columns: repeat(2, 1fr);
      }
   }
}

@media (min-width: $break_desktop) {
   .gallery {
      .gallery_row {
         .big {
            max-width: 28.5rem;
         }
      }
   }
}