* {
   box-sizing: border-box;
   text-rendering: optimizeLegibility;
   font-synthesis: none;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   padding: 0;
   margin: 0;
   font-family: 'Roboto Condensed', sans-serif;
   outline: none;
}

html {
   overflow-x: hidden;
   font-size: 62.5%;
}

body {
   overflow-x: hidden;
   position: relative;
   background-color: $base_color_1;
}

hr {
   margin: 2rem 0;
   border-top: 1px solid lighten($font_color, 25);
}

.collapse_body,
.collapse_body_user {
   display: none;
}

.bold {
   color: $font_color_dark;
   font-weight: 700;
}

a {
   text-decoration: none;

   &:hover {
      cursor: pointer;
   }
}

ul {
   list-style: none;
}

.img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.tag {
   vertical-align: text-bottom;
   margin: 0 1rem .6rem 0;
   display: inline-block;
   padding: .5rem 1.3rem;
   font-size: 1.4rem;
   text-transform: uppercase;
   font-weight: 700;

   &.analyza {
      background-color: $tag_color_3;
      color: $base_color_3;
   }

   &.komentar {
      background-color: $tag_color_1;
      color: $base_color_1;
   }

   &.clanek {
      background-color: $tag_color_2;
      color: $base_color_1;
   }

   &.priroda {
      background-color: $tag_color_4;
      color: $base_color_1;
   }

   &.rozhovor {
      background-color: $tag_color_5;
      color: $base_color_1;
   }
}