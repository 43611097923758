.peclive_vybrano {
   background-color: $base_color_1;
   padding: 2rem 1rem;

   h2 {
      color: $font_color;
      font-weight: 700;
      font-size: 3rem;
      margin-bottom: 1.5rem;
   }

   .row {
      display: grid;
      grid-template-columns: 100%;
      grid-row-gap: 1rem;

      .article {
         position: relative;
         height: inherit;

         &:hover {
            cursor: pointer;

            .info {
               margin: auto;
               min-height: 50%;
               transition: all .2s;
            }
         }

         .info {
            min-height: 30%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba($base_color_4, .75);
            color: $base_color_1;
            font-size: 2rem;
            padding: 2rem;

            .date {
               font-size: 1.4rem;
               color: $font_color_light;
               font-weight: 700;
            }
         }
      }

      .four_box {
         display: grid;
         grid-template-columns: 100%;
         grid-template-rows: repeat(2, 1fr);
         grid-gap: 1rem;

         .article {
            &:hover {
               cursor: pointer;

               .info {
                  margin: auto;
                  min-height: 75%;
                  transition: all .2s;
               }
            }

            .info {
               font-size: 2rem;
               padding: 2rem;
            }
         }
      }
   }
}

@media (min-width: $break_tablet) {
   .peclive_vybrano {
      .row {
         grid-template-columns: 100%;

         .article {
            .info {
               font-size: 3rem;
            }
         }

         .four_box {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
         }
      }
   }
}

@media (min-width: $break_desktop) {
   .peclive_vybrano {
      padding: 4.5rem 1rem 2.8rem 1rem;

      .row {
         grid-template-columns: repeat(2, 1fr);
         grid-template-rows: 43rem;
         grid-column-gap: 2rem;

         .four_box {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-gap: 2rem;
            height: 20.5rem;
         }
      }
   }
}